import { useLocation } from "@reach/router";

export function MetaTitle() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Meta Title"
  } else if (pathname === "leisure") {
    return "Meta Title"
  } else if (pathname === "transportation") {
    return "Meta Title"
  } 
}

export function MetaDescription() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Meta Description"
  } else if (pathname === "leisure") {
    return "Meta Description"
  } else if (pathname === "transportation") {
    return "Meta Description"
  } 
}

export function MetaKeywords() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Meta Keywords"
  } else if (pathname === "leisure") {
    return "Meta Keywords"
  } else if (pathname === "transportation") {
    return "Meta Keywords"
  } 
}

export function MetaImage() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "https://www.cic.lk/wp-content/uploads/2016/04/favicon.png"
  } else {
    return "https://www.cic.lk/wp-content/uploads/2016/04/favicon.png"
  }
}

export function MetaOGTitle() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "MetaOGTitle"
  } else {
    return "MetaOGTitle"
  }
}
