import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
//import Seo from "../components/seo"
//import * as styles from "../components/index.module.css"
//import Home from "../templates/Home/HomePage"
import Home from "./home-page"
import { MetaKeywords, MetaImage, MetaOGTitle } from "../components/meta-tags"

const IndexPage = ({ data }) => {
  const post = data
  const metaKeywords = MetaKeywords()
  const metaImage = MetaImage()
  const metaOGTitle = MetaOGTitle()
  console.log(post)

  const wpdata = useStaticQuery(graphql`
    query {
      allWpPage(filter: { slug: { eq: "home" } }) {
        nodes {
          title
          date
          author {
            node {
              name
            }
          }
          content
        }
      }

      allWpPost {
        nodes {
          slug
          title
          excerpt
          date
          author {
            node {
              name
            }
          }
          content
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        {/* <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} /> */}
        <meta name="keywords" content={metaKeywords} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:title" content={metaOGTitle} />
      </Helmet>
      <Home data={post}></Home>
      {/* <div>
         <h1>Welcome to My Website</h1>
       </div>
      <ul>
         {wpdata.allWpPage.nodes.map(page => (
          <li key={page.slug}>
             <h2>{page.title}</h2>
             <div dangerouslySetInnerHTML={{ __html: page.content }} />
          </li>
         ))}
         {wpdata.allWpPost.nodes.map(post => (
           <li key={post.slug} >
             <h2>{post.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </li>
        ))}
       </ul> */}
    </Layout>
  )
}

export default IndexPage
